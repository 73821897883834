<template>
  <div class="card-level-up">
    <div v-if="cardImage" class="card-level-up__image">
      <atomic-picture :src="cardImage" notLazy />
    </div>

    <div class="card-level-up__container">
      <div class="card-level-up__content">
        <div class="card-level-up__head">
          <div class="card-level-up__head-name">{{ nextLevelName }}</div>
          <div class="card-level-up__head-type">{{ nextLevelContent.bonusType }}</div>
        </div>

        <div class="card-level-up__bonus">
          <div class="card-level-up__bonus-label">{{ bonusLabel }}</div>
          <div class="card-level-up__bonus-value">{{ nextLevelContent.bonusValue }}</div>
        </div>

        <div class="card-level-up__actions">
          <div class="card-level-up__progress">
            <div class="card-level-up__progress-label">
              {{ pointsLeftLabel }} <span>{{ pointsLeft }} LP</span>
            </div>

            <bonuses-progress :progress="currentLevelProgress" />
          </div>

          <button-base
            v-if="!props.hideButton"
            class="card-level-up__info"
            type="secondary-2"
            size="sm"
            @click="openModal('loyalty-bonus-details')"
          >
            <atomic-icon id="info" />
          </button-base>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    hideButton?: boolean;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const loyaltyStore = useLoyaltyStore();
  const { openModal } = useModalStore();
  const { nextLevelName, currentLevelProgress, loyaltyAccount, currentPoints, nextLevelPoints } =
    storeToRefs(loyaltyStore);

  const bonusTypeLabels = getContent(
    globalComponentsContent,
    defaultLocaleGlobalComponentsContent,
    'loyalty.bonusType'
  );

  const bonusLabel = getContent(
    globalComponentsContent,
    defaultLocaleGlobalComponentsContent,
    'loyalty.levelBonusLabel'
  );

  const pointsLeftLabel = getContent(
    globalComponentsContent,
    defaultLocaleGlobalComponentsContent,
    'loyalty.pointsLeftLabel'
  );

  const cardImage = getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.levelUpImage');

  const nextLevelContent = computed<{ bonusValue?: string; bonusType?: string }>(() => {
    const nextLevelOrder = loyaltyAccount.value?.nextLevel?.order || 0;
    const levelContent = getContent(
      globalComponentsContent,
      defaultLocaleGlobalComponentsContent,
      `loyalty.levelUpBonuses.${nextLevelOrder}`
    );

    return {
      bonusValue: levelContent?.value,
      bonusType: bonusTypeLabels?.[levelContent?.type || 'special'],
    };
  });

  const pointsLeft = computed(() => {
    return Math.ceil(nextLevelPoints.value - currentPoints.value);
  });
</script>

<style src="~/assets/styles/components/card/level-up.scss" lang="scss" />
